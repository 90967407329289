const validations = {
    notEmptyString: (value) => {
        const isValid = value.length > 0;
        return { isValid: isValid, message: isValid ? undefined : "Input must not be empty" }
    },
    notEmptyStringHtml: (value) => {
        const isValid = value.length > 0 && value != "<p><br></p>";
        return { isValid: isValid, message: isValid ? undefined : "Input must not be empty" }
    },
    isNumber: (value => {
        const isValid = !isNaN(value);
        return { isValid: isValid, message: isValid ? undefined : "Input must be a number" }
    })
}

export default validations