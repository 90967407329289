import React, { useEffect, useRef } from "react";
import useForm from "../../hooks/useForm/useForm";
import { useNavigate } from "react-router-dom";
import qs from "qs";
import ApiManager from "../../utils/ApiManager";
import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation";
import SuccessAnimation from "../../components/SuccessAnimation/SuccessAnimation";
import StepWizard from "react-step-wizard";
import WizardNavigation from "../../components/WizardNavigation/WizardNavigation";

import Step1 from "./Step1";
import Step2 from "./Step2";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  centerWizard: {
    width: "100%",
    flexDirection: "column-reverse",
    display: "flex",
  },
});

const WriteRecipeForm = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  const [recipe, setRecipe] = React.useState(null);
  const [recipeUUID, setRecipeUUID] = React.useState(null);
  const [recipeImage, setRecipeImage] = React.useState(null);
  const [preloadedRecipeImage, setPreloadedRecipeImage] = React.useState(null);

  const [successAnimationVisible, setSuccessAnimationVisibile] =
    React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isRecipeBeingUpdated, setIsRecipeBeingUpdated] = React.useState(false);

  const {
    formValues,
    presetFormValues,
    errors,
    handleChange,
    handleSubmit,
    formIsValid,
  } = useForm(handleRecipeSubmit);

  const handleLoadRecipe = async () => {
    try {
      setIsLoading(true);
      let loadedRecipe;
      loadedRecipe = await ApiManager.loadRecipe(recipeUUID);

      const values = {
        name: loadedRecipe.data.name,
        cookingTime: loadedRecipe.data.cooking_time,
        recipeText: loadedRecipe.data.description,
        recipeCooked: loadedRecipe.data.recipeCooked,
      };
      presetFormValues(values);

      if (
        loadedRecipe.data.recipeImages &&
        loadedRecipe.data.recipeImages.length > 0
      ) {
        setPreloadedRecipeImage(loadedRecipe.data.recipeImages[0].image_src);
      }

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleImageChange = (file) => {
    setRecipeImage(file);
  };

  function handleRecipeSubmit() {
    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);

        let _formValues = formValues;
        _formValues.recipeImage = recipeImage;
        //Check if we are creating a new recipe or updating an existing one
        if (isRecipeBeingUpdated) {
          await ApiManager.updateRecipe(recipeUUID, _formValues);
        } else {
          await ApiManager.createRecipe(_formValues);
        }

        setIsLoading(false);
        resetCompleteComponent();
        resolve();
      } catch (error) {
        setIsLoading(false);

        console.error("Failure: " + JSON.stringify(error));
        reject(error);
      }
    });
  }

  const resetCompleteComponent = () => {
    setSuccessAnimationVisibile(true);

    setTimeout(function () {
      setSuccessAnimationVisibile(false);
      // If we are currently updating a recipe, the user should be sent to the recipe book
      // after finishing the update
      // if (isRecipeBeingUpdated) {
      navigate("/app/recipe-book");
      // }
    }, 2000);
  };

  React.useEffect(() => {
    if (qs.parse(window.location.search, { ignoreQueryPrefix: true }).recipe) {
      setRecipeUUID(
        qs
          .parse(window.location.search, { ignoreQueryPrefix: true })
          .recipe.toString()
      );
      setIsRecipeBeingUpdated(true);
    } else {
      setIsRecipeBeingUpdated(false);
    }
  }, []);

  React.useEffect(() => {
    if (recipeUUID) {
      handleLoadRecipe();
    }
  }, [recipeUUID]);

  return (
    <>
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <>
          {/* {JSON.stringify(formValues)} */}
          {successAnimationVisible ? <SuccessAnimation /> : null}
          <StepWizard
            className={classes.centerWizard}
            nav={
              <WizardNavigation
                stepNames={["Name and Image", "The recipe", "Additional info"]}
                // stepsValid={[slocStepValid, dataTypeStepValid, emailAndTimesStepValid]}
              />
            }
          >
            <Step1
              errors={errors}
              onFormChange={handleChange}
              formValues={formValues}
              onImageChange={handleImageChange}
              preloadedRecipeImage={preloadedRecipeImage}
            />
            <Step2
              errors={errors}
              onFormChange={handleChange}
              formValues={formValues}
              handleSubmit={handleSubmit}
              formIsValid={!formIsValid}
            />
          </StepWizard>
        </>
      )}
    </>
  );
};

export default WriteRecipeForm;
