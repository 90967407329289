import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles({
    onTop: {
      zIndex: "5",
      position: "fixed",
      margin: "25%"
    }
  });

function LoadingAnimation() {
    const classes = useStyles();

    return (
        <CircularProgress className={classes.onTop} size= {100} color="primary" />
    );
}

export default LoadingAnimation;
