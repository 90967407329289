

import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import RichTextEditor from 'react-rte';

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
        margin: 'auto'
    },
    media: {
        height: 200,
        width: '100%',
        objectFit: 'cover'
    },
    gridI: {
        paddingBottom: 20,
    }
});

const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Underline', style: 'UNDERLINE' }
    ],
    BLOCK_TYPE_DROPDOWN: [
        { label: 'Normal', style: 'unstyled' },
        { label: 'Heading Large', style: 'header-one' },
        { label: 'Heading Medium', style: 'header-two' },
        { label: 'Heading Small', style: 'header-three' }
    ],
    BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item' },
        { label: 'OL', style: 'ordered-list-item' }
    ]
};

function RTE({ onChange, clearEditor, text, className }) {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(RichTextEditor.createEmptyValue())

    const handleChange = (htmlMarkup) => {
        setValue(htmlMarkup)
        if (onChange) {
            onChange(
                // .toString can be used to convert the markup to html
                htmlMarkup.toString('html')
            );
        }
    };

    const clear = () => {
        setValue(RichTextEditor.createEmptyValue());
    }

    React.useEffect(() => {
        if (clearEditor) clear()
    }, [clearEditor])

    // If we provide a text from the parent component, set it as current value
    // The dependency array does not contain the text variable since we only want it to happen on mount
    React.useEffect(() => {
        if(text){
            setValue(RichTextEditor.createValueFromString(text, 'html'))
        }
    },[])

    return (
        <RichTextEditor
            value={value}
            onChange={handleChange}
            toolbarConfig={toolbarConfig}
            className={className}
        />
    );

}

export default RTE;
