import React from "react";

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { createStyles, makeStyles, useTheme, Typography, Button } from "@material-ui/core";


export default function WizardNavigation(props) {


    const theme = useTheme();

    const newStyles = createStyles({
        navBar: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
            marginBottom: "20px"
        },
        navButtons: {
            margin: "0 10px 0 10px",
            fontWeight: "bold",
            padding: "5px",
            "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
                marginRight: "0px"
            },

        },
        buttonRootStyle: {
            background: theme.palette.accentBlue.main,
            "&:hover": {
                background: theme.palette.accentBlue.dark
            },
            color: "white"
        },
        disabledButton: {
            fontWeight: "lighter"
        },
        stepText: {
            marginLeft: "10px",
            marginRight: "10px"
        }
    });

    const useStyles = makeStyles(newStyles);
    const classes = useStyles();

    const previousButton = () => {
        if (props.stepsValid) {
            return <Button
                onClick={() => {
                    props.previousStep();
                }}
                className={`${classes.navButtons}`}
                classes={{
                    root: classes.buttonRootStyle
                }}
            >
                <ArrowBackIcon className={classes.icon} /> {<span className={classes.stepText}>{`Previous step`}</span>}
            </Button>
        } else {
            return <Button
                onClick={() => {
                    props.previousStep();
                }}
                className={`${classes.navButtons}`}
                classes={{
                    root: classes.buttonRootStyle
                }}
            >
                <ArrowBackIcon className={classes.icon} /> {<span className={classes.stepText}>{`Previous step`}</span>}
            </Button>
        }
    }

    const nextButton = () => {
        if (props.stepsValid) {
            return <Button
                variant="contained"
                disabled={!props.stepsValid[props.currentStep - 1]}
                className={`${!props.stepsValid[props.currentStep - 1] ? classes.disabledButton : ""} ${classes.navButtons}`}
                onClick={() => {
                    props.nextStep();
                }}
                classes={{
                    root: classes.buttonRootStyle
                }}
            >
                {<span className={classes.stepText}>{`Next step`}</span>} <ArrowForwardIcon className={classes.icon} />
            </Button>
        } else {
            return <Button
                variant="contained"
                onClick={() => {
                    props.nextStep();
                }}
                className={`${classes.navButtons}`}
                classes={{
                    root: classes.buttonRootStyle
                }}
            >
                {<span className={classes.stepText}>{`Next step`}</span>} <ArrowForwardIcon className={classes.icon} />
            </Button>
        }
    }


    return (
        <div className={classes.navBar}>
            {props.currentStep > 1 ? previousButton() : null}
            <Typography align={"center"}>{`Step ${props.currentStep} - ${props.stepNames[props.currentStep - 1]}`}</Typography>
            { props.currentStep < props.totalSteps ? nextButton() : null}
        </div>
    );
};
