import React from "react";
import { Grid, TextField } from '@material-ui/core'
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Thumbnail from '../../components/Thumbnail'
import FileUploader from '../../components/FileUploader/FileUploader'
import validations from "../../hooks/useForm/validations"

const useStyles = makeStyles({
    cardHeader: {
        fontFamily: "Lobster"
    },
    step: {
        marginTop: "30px"
    },
    headerImage: {
        height: "150px"
    },
    headerArea: {
        marginTop: "20px",
        marginBottom: "15px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    imageArea: {
        marginTop: "25px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    contentArea: {
        display: "flex",
        alignItems: "center",
        flexFlow: "column"
    }
});

export default function Step1({ errors, onFormChange, formValues, onImageChange, preloadedRecipeImage }) {
    const classes = useStyles();

    const [selectedFile, setSelectedFile] = React.useState(null);

    // We use this effect to manually call the form validation once on mounting of the component.
    // This function does not run when we already prepopulated values
    React.useEffect(() => {
        if(formValues == {}) onFormChange({ target: { name: "name", value: "" } }, [validations.notEmptyString])
    }, [])

    return (
        <Grid className={classes.step}>
            <Grid item xs={12} className={classes.gridI}>
                <Card className={classes.root}>
                    <div className={classes.headerArea}>
                        <img className={classes.headerImage} src={'/images/healthy-food.svg'} />
                        <CardHeader
                            titleTypographyProps={{ className: classes.cardHeader }}
                            title={"Tell us about your recipe"}
                        />
                    </div>
                    <CardContent className={classes.contentArea}>
                        <TextField
                            label="Name"
                            value={formValues.name || ""}
                            onChange={(e) => onFormChange(e, [validations.notEmptyString])}
                            name="name"
                            placeholder={"My awesome recipe"}
                            error={errors.name !== undefined && errors.name !== null}
                            helperText={errors.name}
                        />
                        <br />
                        <div className={classes.imageArea}>
                            <FileUploader
                                onFileSelectSuccess={(file) => {setSelectedFile(file); onImageChange(file)}}
                            />
                            <Thumbnail file={selectedFile} url={preloadedRecipeImage} />
                        </div>
                    </CardContent>
                </Card>
            </Grid >
        </Grid>
    );
}

