import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#800020',
    },
    secondary: {
      main: '#fffeed',
    },
    accentColor: {
      main: '#416970',
      light: "#9c032a"
    },
    accentBlue: {
      main: '#006080',
      dark: "#0d556d"
    },
    offWhite: {
      main: "#fbfbfb"
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fdf7ed",
    },
  },
});

export default theme;
