import React from "react";

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { Autocomplete } from '@material-ui/lab';
import { VariableSizeList } from 'react-window';

import { createStyles, makeStyles, useTheme, Typography, Button, TextField } from "@material-ui/core";

import ingredients from './ingredients'


export default function Ingredient(props) {


    const theme = useTheme();

    const newStyles = createStyles({
        row: {
            display: "grid",
            gridTemplateColumns: "30% 1fr 2fr",
            gridTemplateRows: "50px",
            alignItems: "center",
            justifyItems: "center",
            marginBottom: "20px",
            width: "auto"
        },
        ingredientAutoComplete: {
            width: "100%"
        }

    });

    const useStyles = makeStyles(newStyles);
    const classes = useStyles();

    return (
        <div className={classes.row}>
            <Autocomplete
                id="ingredients"
                className={classes.ingredientAutoComplete}
                freeSolo
                options={ingredients}
                renderInput={(params) => (
                    <TextField {...params} label="Ingredient" margin="normal" variant="outlined" />
                )}
            />
            <div>a </div>
            <div>nice test</div>
        </div>
    );
};
