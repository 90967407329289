import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import LoadingAnimation from '../components/LoadingAnimation/LoadingAnimation'

const useStyles = makeStyles({
    root: {
        margin: 'auto',
        marginTop: "30px",
        width: "auto",
        "@media (max-width: 992px)": {
            maxWidth: "90%",
        },
        "@media (min-width: 993px)": {
            maxWidth: "75%",
        },

        "@media (min-width: 1500px)": {
            maxWidth: "50%",
        },
        height: "auto",
        animation: `$popup 600ms`,

        borderStyle: "solid",
        border: "#800020",
        borderRadius: "5px"
    },
    "@keyframes popup": {
        "0%": {
            transform: "scale(1)"
        },
        "50%": {
            transform: "scale(1.2)"
        },
        "100%": {
            transform: "scale(1)"
        }
    },

    media: {
        height: 200,
        width: '100%',
        objectFit: 'cover'
    },
    gridI: {
        paddingBottom: 20,
    }
});

function Thumbnail({ file, url }) {
    const classes = useStyles();
    const theme = useTheme();
    const [loading, setLoading] = React.useState(false);
    const [thumb, setThumb] = React.useState(undefined);

    React.useEffect(() => {
        if (!file) return

        let reader = new FileReader();
        setLoading(true)

        reader.onloadend = () => {
            setLoading(false);
            setThumb(reader.result);
        };

        reader.readAsDataURL(file);

    }, [file])

    if (!file && !url) { return null; }

    if (loading) { return <LoadingAnimation /> }

    return (<img src={thumb ? thumb : url}
        alt={file ? file.name : "image"}
        className={classes.root}
        // className="img-thumbnail mt-2"
        height={200}
        width={200} />);
}

export default Thumbnail;