import { useState, useEffect } from 'react';

const useForm = (submitCallback) => {

  const [formValues, setFormValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);

  useEffect(() => {
    // if (Object.keys(errors).length === 0 && isSubmitting) {
    //   callback();
    // }

    for (let err in errors) {
      if (errors[err] !== null) {
        setFormIsValid(false);
        return;
      }
    }

    setFormIsValid(true);

  }, [errors]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    // setErrors(validate(values));
    setIsSubmitting(true);
    submitCallback();
  };

  const handleChange = (event, validators) => {

    // Check if event.persist is a function to handle manual fake events
    if (event.persist) event.persist();
    setFormValues(values => ({ ...values, [event.target.name]: event.target.value }));

    if (validators) {
      validate(event.target.value, event.target.name, validators)
    }
  };

  const validate = (value, name, validators) => {
    // If the inputs are not valid, add the error message to the object and return
    for (let x = 0; x < validators.length; x++) {
      const result = validators[x](value)
      if (!result.isValid) {
        setErrors(values => ({ ...values, [name]: result.message }));
        return;
      }
    }

    // If the input is valid, remove all previous error messages for this input (if any)
    if (errors[name]) {
      setErrors(values => ({ ...values, [name]: null }));
    }
  }

  // Sometimes we would like to prepopulate our form with existing values. This function expects an array with correctly named key value pairs for a specific form
  const presetFormValues = (newValues) => {
    // for (let x = 0; x < values.length; x++){
      
    // }

    setFormValues(newValues);
    
  }

  return {
    handleChange,
    handleSubmit,
    formValues,
    presetFormValues,
    errors,
    formIsValid
  }
};

export default useForm;