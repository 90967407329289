import React from "react";
import ResponsiveDrawer from "./components/ResponsiveDrawer.jsx";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import RecipeBook from "./pages/RecipeBook";
import RecipeEditor from "./pages/RecipeEditor/RecipeEditor";
import Friends from "./pages/Friends";
import WelcomePage from "./pages/WelcomePage";
import Recipe from "./pages/Recipe";
import Login from "./pages/Login";
import Register from "./pages/Register";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<WelcomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />

        {/* Nested routes under /app */}
        <Route path="/app" element={<ResponsiveDrawer />}>
          <Route path="recipe-book/recipe" element={<Recipe />} />
          <Route path="recipe-book" element={<RecipeBook />} />
          <Route path="write-recipe" element={<RecipeEditor />} />
          <Route path="edit-recipe" element={<RecipeEditor />} />
          <Route path="friends" element={<Friends />} />
          {/* Default redirect inside /app */}
          <Route path="*" element={<Navigate to="recipe-book" />} />
        </Route>

        {/* Catch-all route for unknown paths */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
