import React from "react";
import { Grid } from '@material-ui/core'
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
        margin: 'auto'
    },
    media: {
        height: 200,
        width: '100%',
        objectFit: 'cover',
    },
    mediaHover: {
        "&:hover": {
            background: "#f2d09b"
        }
    },
    gridI: {
        paddingBottom: 20,
    }
});

function RecipeGridItem({ recipe }) {
    const classes = useStyles();
    const theme = useTheme();
    const { name, rating, recipeImages } = recipe;

    const recipeLink = `/app/recipe-book/recipe?recipeUUID=${recipe.uuid}`

    return (
        <Grid item xs={12} md={6} lg={4} xl={3} className={classes.gridI}>
            <Link to={recipeLink} >
                <Card className={classes.root}>
                    <CardActionArea className={classes.mediaHover}>
                        <CardMedia
                            className={classes.media}
                            image={recipeImages && recipeImages.length > 0 ? recipeImages[0].image_src : ""}
                            title="My recipe"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                {`${name}`}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Link>
        </Grid >
    );
}

export default RecipeGridItem;
