import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ApiManager from "../utils/ApiManager";

import FriendGridItem from "../components/FriendGridItem/FriendGridItem";

import LoadingAnimation from "../components/LoadingAnimation/LoadingAnimation";

const useStyles = makeStyles((theme) => ({
  friendTitle: {
    fontFamily: "Lobster",
    marginBottom: "60px",
  },
}));

function Friends(props) {
  const classes = useStyles();
  const [friends, setFriends] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  console.log("Rendering Friends");

  const handleLoad = async function () {
    try {
      setIsLoading(true);
      const loadedFriends = await ApiManager.loadFriends();
      if (loadedFriends.data) {
        setFriends(loadedFriends.data);
      }
      setIsLoading(false);

      return;
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const formatFriends = React.useCallback(
    (friendsArr) => {
      return friendsArr.map((friend) => {
        return <FriendGridItem friend={friend}></FriendGridItem>;
      });
    },
    [friends]
  );

  React.useEffect(() => {
    handleLoad();
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <>
          <Typography
            className={classes.friendTitle}
            variant="h2"
            align="center"
          >
            {"Your cool recipe friends"}
          </Typography>
          <Grid container spacing={10}>
            {formatFriends(friends)}
          </Grid>
        </>
      )}
    </>
  );
}

export default Friends;
