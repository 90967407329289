import React from "react";
import { Outlet } from "react-router-dom"; // Import Outlet for nested routing
import SelectedMenuContext from "../contexts/menuContext";

import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import CreateIcon from "@material-ui/icons/Create";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import PeopleIcon from "@material-ui/icons/People";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  body: {
    background: theme.background,
  },
  menuTitle: {
    color: "white",
    fontFamily: "Lobster",
  },
  root: {
    display: "flex",
    background: theme.background,
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    color: "white",
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function ResponsiveDrawer(props) {
  const { container } = props;
  const classes = useStyles();

  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [selectedMenu, setSelectedMenu] = React.useState("/");
  const [selectedMenuText, setSelectedMenuText] = React.useState("RecipeFox");

  const handleMenuClick = (menuText) => {
    setSelectedMenu(menuText);
    setMobileOpen(false);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const linkList = [
    { link: "recipe-book", sidebarText: "Recipe Book", icon: <MenuBookIcon /> },
    { link: "write-recipe", sidebarText: "Write Recipe", icon: <CreateIcon /> },
    { link: "friends", sidebarText: "Friends", icon: <PeopleIcon /> },
  ];

  React.useEffect(() => {
    const menuText = linkList.find((el) => el.link === selectedMenu);
    if (menuText) setSelectedMenuText(menuText.sidebarText);
  }, [selectedMenu]);

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        {linkList.map((el) => (
          <ListItem
            key={el.sidebarText}
            component={Link}
            to={"/app/" + el.link}
            onClick={() => handleMenuClick(el.link)}
          >
            <ListItemIcon>{el.icon}</ListItemIcon>
            <ListItemText primary={el.sidebarText} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <SelectedMenuContext.Provider value={{ selectedMenu, setSelectedMenu }}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap className={classes.menuTitle}>
              {selectedMenuText}
            </Typography>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>

        <main className={`${classes.content}`}>
          <div className={classes.toolbar} />
          {/* Add the Outlet here to render nested routes */}
          <Outlet />
        </main>
      </div>
    </SelectedMenuContext.Provider>
  );
}

ResponsiveDrawer.propTypes = {
  container: PropTypes.instanceOf(
    typeof Element === "undefined" ? Object : Element
  ),
};

export default ResponsiveDrawer;
