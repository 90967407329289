import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from '@material-ui/core'
import { Link } from "react-router-dom";
import Avatar from "boring-avatars";
import AccessTimeIcon from '@material-ui/icons/AccessTime';


const useStyles = makeStyles({
    friend: {
        display: "grid",
        animation: "$fadein 1s",
        justifyContent: "center"
    },
    "@keyframes fadein": {
        "0%": {
            opacity: 0
        },
        "100%": {
            opacity: 1
        }
    },
    friendAvatar: {
        placeSelf: "center",
        transition: "all .2s ease-in-out",
        "&:hover": {
            transform: "scale(1.1)"
        }
    },
    friendsSince: {
        display: "flex",
        alignItems: "center"
    },
    friendsSinceText: {
        placeSelf: "center",
        marginLeft: "10px"
    },
    friendName: {
        placeSelf: "center"
    }
});

function FriendGridItem(props) {
    const classes = useStyles();

    const { friend } = props;

    const friendLink = React.useMemo(() => {
        return `/app/recipe-book/?user=${friend.id}`
    }, [])

    return (
        <Grid key={friend.id} item xs={6} md={3} className={classes.friend} >
            <div className={classes.friendAvatar}>
                <Avatar
                    size={100}
                    name={friend.username}
                    variant="beam"
                    colors={["#92A1C6", "#146A7C", "#F0AB3D", "#C271B4", "#C20D90"]}
                />
            </div>

            <Link to={friendLink} >
                <Typography variant="h6" align={"center"} className={classes.friendName}>{friend.username} </Typography>
            </Link>
            <div className={classes.friendsSince}>
                <AccessTimeIcon />
                <Typography className={classes.friendsSinceText}>{`${new Date(friend.created_at)
                    .toLocaleDateString(undefined, {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric"
                    })}`}</Typography>
            </div>

        </Grid>
    );
}

export default FriendGridItem;
