import React, { useRef } from 'react'
import { Input, Button } from '@material-ui/core'
import { makeStyles, useTheme } from "@material-ui/core/styles";
import BackupIcon from '@material-ui/icons/Backup';

const useStyles = makeStyles(theme => ({
    uploadButton: {
        background: "#800020",
        "&:hover": {
            background: "#9c032a"
        },
        color:"white"
    },
    uploadIcon: {
        marginRight: "5px"
    }
}));

export default ({ onFileSelectSuccess, onFileSelectError }) => {

    const theme = useTheme();
    const classes = useStyles(theme);
    
    const fileInput = useRef(null)

    const handleFileInput = (e) => {
        // handle validations
        const file = e.target.files[0];
        onFileSelectSuccess(file);
    };

    return (
        <div className="file-uploader">
            <input type="file"
                onChange={handleFileInput}
                ref={fileInput}
                hidden />
            <Button 
            onClick={e => fileInput.current && fileInput.current.click()} 
            className={classes.uploadButton}
            variant="contained" >
                <BackupIcon className={classes.uploadIcon} /> {"Upload an image of your dish"}
            </Button>
        </div>
    )
}