import React from "react";
import { Grid, Input, InputLabel, FormControl, FormControlLabel, Checkbox, InputAdornment, Typography, Switch, Button, TextField } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import RichTextEditor from '../../components/RichTextEditor'
import IngredientSelector from '../../components/IngredientSelector/IngredientSelector'
import validations from "../../hooks/useForm/validations"

export default function Step1({ errors, onFormChange, formValues, recipeCooked, onRecipeCookedChange, handleSubmit, formIsValid }) {

    const theme = useTheme();

    const useStyles = makeStyles({
        cardHeader: {
            fontFamily: "Lobster"
        },
        step: {
            marginTop: "30px"
        },
        headerArea: {
            marginTop: "20px",
            marginBottom: "15px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        },
        contentArea: {
            display: "flex",
            alignItems: "center",
            flexFlow: "column"
        },
        editor: {
            minWidth: "90%",
            minHeight: "300px",
            marginBottom: "25px"
        },
        instructionText: {
            fontWeight: "bold",
            marginBottom: "10px"
        },
        timeInput: {
            maxWidth: "100px"
        },
        elementGroupWrapper: {
            marginBottom: "25px",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            textAlign: "center"
        },
        saveButton: {
            margin: "0 10px 0 10px",
            fontWeight: "bold",
            padding: "5px",
            "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
                marginRight: "0px"
            },

        },
        buttonRootStyle: {
            background: theme.palette.primary.main,
            "&:hover": {
                background: theme.palette.primary.light
            },
            color: "white"
        },
        buttonText: {
            marginLeft: "10px",
            marginRight: "10px"
        }
    });

    const classes = useStyles();

    // Since the rich text editor is a special component compared to input fields, we need to treat its changes in its own function
    const handleEditorContentChange = (text) => {
        onFormChange({ target: { name: "recipeText", value: text } }, [validations.notEmptyStringHtml])
    }

    // We use this effect to manually call the form validation once on mounting of the component.
    // This function does not run when we already prepopulated values
    React.useEffect(() => {
        if(formValues == {}){
            onFormChange({ target: { name: "cookingTime", value: "" } }, [validations.notEmptyString, validations.isNumber])
            onFormChange({ target: { name: "recipeText", value: "" } }, [validations.notEmptyStringHtml])
            onFormChange({ target: { name: "recipeCooked", value: false } })
        }   
    }, [])

    return (
        <Grid className={classes.step}>
            <Grid item xs={12}>
                <Card>
                    <div className={classes.headerArea}>
                        <CardHeader
                            titleTypographyProps={{ className: classes.cardHeader }}
                            title={"Recipe instructions"}
                        />
                    </div>
                    <CardContent className={classes.contentArea}>
                        <IngredientSelector />
                        <RichTextEditor className={classes.editor}
                            onChange={handleEditorContentChange}
                            text={formValues.recipeText}
                        />
                        <div className={classes.elementGroupWrapper}>
                            <Typography className={classes.instructionText}>{"How much time does this recipe roughly take?"}</Typography>
                            <TextField
                                value={formValues.cookingTime || ""}
                                onChange={(e) => onFormChange(e, [validations.notEmptyString, validations.isNumber])}
                                name="cookingTime"
                                placeholder={"45"}
                                error={errors.cookingTime !== undefined && errors.cookingTime !== null}
                                helperText={errors.cookingTime}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            {"min"}
                                        </InputAdornment>
                                }
                                }
                            />
                        </div>
                        <div className={classes.elementGroupWrapper}>
                            <Typography className={classes.instructionText}>{"Have you already cooked this recipe?"}</Typography>
                            <FormControlLabel
                                classes={{ root: classes.instructionText }}
                                control={<Switch color="primary"
                                    name="recipeCooked"
                                    checked={formValues.recipeCooked}
                                    onChange={(e) => onFormChange({ target: { name: "recipeCooked", value: e.target.checked } })}
                                />}
                            />
                        </div>
                        <Button
                            variant="contained"
                            disabled={formIsValid}
                            className={`${classes.saveButton}`}
                            onClick={() => {
                                handleSubmit()
                            }}
                            classes={{
                                root: classes.buttonRootStyle
                            }}
                        >
                            {<span className={classes.buttonText}>{`Save recipe`}</span>} <SaveIcon className={classes.icon} />
                        </Button>

                    </CardContent>
                </Card>
            </Grid >
        </Grid>
    );
}

