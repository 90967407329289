import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Grid,
  Paper,
  Typography,
  Link,
  Toolbar,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ApiManager from "../utils/ApiManager";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    background: theme.palette.primary.main,
  },
  loginForm: {
    justifyContent: "center",
    minHeight: "90vh",
  },
  buttonBlock: {
    width: "100%",
  },
  loginBackground: {
    justifyContent: "center",
    minHeight: "30vh",
    padding: "50px",
  },
  loginTitle: {
    marginBottom: "2vh",
  },
  loginBody: {
    backgroundImage: 'url("/images/bg_cooking_min.jpg")',
    backgroundSize: "cover",
  },
}));

export default function Recipe(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [password, setPassword] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();

  const handleSubmitLogin = async () => {
    try {
      setLoading(true);
      await ApiManager.login(email, password);
      navigate("/app/recipe-book");
    } catch (error) {
      setLoading(false);
      setError(error);
      console.error(error);
    }
  };

  return (
    <div>
      <Paper elevation={3}>
        {" "}
        <Toolbar className={classes.toolbar}></Toolbar>
      </Paper>
      <Grid
        className={classes.loginBody}
        container
        spacing={0}
        justify="center"
        direction="row"
      >
        {loading ? <CircularProgress color="secondary" /> : null}
        <Grid item>
          <Grid
            container
            direction="column"
            justify="center"
            spacing={2}
            className={classes.loginForm}
          >
            <Paper
              variant="elevation"
              elevation={2}
              className={classes.loginBackground}
            >
              <Grid className={classes.loginTitle} item>
                <Typography component="h1" variant="h5">
                  Lögin
                </Typography>
              </Grid>
              <Grid item>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmitLogin();
                  }}
                >
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <TextField
                        type="email"
                        placeholder="Email"
                        fullWidth
                        name="username"
                        variant="outlined"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        required
                        autoFocus
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        type="password"
                        placeholder="Password"
                        fullWidth
                        name="password"
                        variant="outlined"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        required
                      />
                    </Grid>
                    <Grid item>
                      <span className="error">{error}</span>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={classes.buttonBlock}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  Forgot Password?
                </Link>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
