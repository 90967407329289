import React from "react";

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import Ingredient from './IngredientRow'

import { createStyles, makeStyles, useTheme, Typography, Button } from "@material-ui/core";


export default function IngredientSelector(props) {


    const theme = useTheme();

    const newStyles = createStyles({
        root: {
            width: "70%"
        }
        // buttonRootStyle: {
        //     background: theme.palette.accentBlue.main,
        //     "&:hover": {
        //         background: theme.palette.accentBlue.dark
        //     },
        //     color: "white"
        // }
    });

    const useStyles = makeStyles(newStyles);
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Ingredient />
        </div>
    );
};
