import axios from "axios";

export default class ApiManager {

    static loadAllRecipes = async (userId) => {
        const res = this.get(`/api/recipe/all/${userId}`);
        return res
    }

    static loadRecipe = async (recipeUUID) => {
        const res = this.get(`/api/recipe/${recipeUUID}`);
        return res
    }

    static deleteRecipe = async (recipeUUID) => {
        const res = this.post(`/api/recipe/delete/${recipeUUID}`);
        return res
    }

    static createRecipe = async (values) => {
        let data = new FormData();
        data.append("recipeImage", values.recipeImage);
        data.append("name", values.name);
        data.append("description", values.recipeText);
        data.append("recipeCooked", values.recipeCooked);
        data.append("cookingTime", values.cookingTime);

        const res = this.post(`/api/recipe/create`, data)
        return res
    }

    static updateRecipe = async (recipeUUID, values) => {
        let data = new FormData();
        data.append("recipeImage", values.recipeImage);
        data.append("name", values.name);
        data.append("description", values.recipeText);
        data.append("recipeCooked", values.recipeCooked);
        data.append("cookingTime", values.cookingTime);

        const res = this.post(`/api/recipe/update/${recipeUUID}`, data)
        return res
    }

    static loadFriends = async () => {
        const res = this.get(`/api/user/friends`);
        return res
    }

    static login = async (email, password) => {
        try {
            const res = await this.post(`/api/authentication/login`, { email: email, password: password })
            if (res.data.jwtToken && res.data.userId) {
                window.localStorage.setItem("jwtToken", res.data.jwtToken);
                window.localStorage.setItem("userId", res.data.userId);
            } else {
                throw("Did not receive sufficient login information from the server. Please retry")
            }

            return
        } catch (error) {
            throw (error)
        }
    }

    static register = async (email, username, password, inviteCode) => {
        try {
            const res = await this.post(`/api/authentication/register`, { username: username, email: email, password: password, inviteCode: inviteCode })
            if (res.data.jwtToken && res.data.userId) {
                window.localStorage.setItem("jwtToken", res.data.jwtToken);
                window.localStorage.setItem("userId", res.data.userId);
            } else {
                throw("Did not receive sufficient login information from the server. Please retry")
            }

            return
        } catch (error) {
            throw (error)
        }
    }

    static async get(url) {
        try {

            const jwtToken = window.localStorage.getItem("jwtToken");

            let config = {
                headers: {
                    authorization: jwtToken
                }
            }

            const res = await axios.get(url, config)
            return res
        } catch (error) {
            if (error.response.data && error.response.data.redirect) {
                window.location.href = error.response.data.redirect;
            }
            if (error.response.status === 401) {
                await this.refreshToken();
                return this.get(url);
            }
            if (error.response.data && error.response.data.err) {
                throw (error.response.data.err)
            } else {
                throw (error.response.data)
            }
        }
    }

    static async post(url, body) {
        try {

            const jwtToken = window.localStorage.getItem("jwtToken");

            let config = {
                headers: {
                    authorization: jwtToken
                }
            }

            const res = await axios.post(url, body, config)
            return res
        } catch (error) {
            if (error.response.data && error.response.data.redirect) {
                window.location.href = error.response.data.redirect;
            }
            if (error.response.status === 401) {
                await this.refreshToken();
                return this.post(url, body);
            }
            if (error.response.data && error.response.data.err) {
                throw (error.response.data.err)
            } else {
                throw (error.response.data)
            }
        }
    }

    static async refreshToken() {
        try {
            const res = await this.post(`/api/authentication/refresh`)
            if (res.data.jwtToken) {
                window.localStorage.setItem("jwtToken", res.data.jwtToken);
            } else {
                console.log("Could not refresh token.")
            }

            return
        } catch (error) {
            throw (error)
        }
    }
}
